
import React, {useState, useEffect } from "react"
import { Row, Col, Button, Drawer, Space } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useAreas } from '../../hooks/useAreas'
import HelmetComponent from '../../components/Helmet'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import { CloseOutlined } from '@ant-design/icons'
import CheckBoxEventComponent from '../../components/CheckBoxGroup/Event'
import CheckBoxDatesComponent from '../../components/CheckBoxGroup/Date'
import CheckBoxMunicipalityComponent from '../../components/CheckBoxGroup/MuniciplaityByMunicipality'
import utils from '../../utils'
import i18n from '../../translation'
import ViewEventsComponent from './viewEvents'
import LiefletComponent from './Lieflet'

import MapSvg from '../../img/svg/map'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useEventsMapWithSeoTags } from '../../hooks/useEvent'
// import { useParams } from 'react-router-dom'

const EventsByMunicipality: React.FC = () => {

  const { t } = useTranslation()
  // const { municipality } = useParams()

  


  const url: string = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  
  const { isLoading, isFetching, Events, refetch } = useEventsMapWithSeoTags(url, 180)

  const { areas } = useAreas()
  
  // const [nameMunicipality, setNameMunicipality] = useState<string>('')
  // const [url, setUrl] = useState<string>('')
  
  // console.log('Events', Events)


  const ReduxPageNumberEvents: number = useSelector((state: RootState) => state.Events.EventsPageNumberRedux)
  const ReduxPageSizeEvents: number = useSelector((state: RootState) => state.Events.EventsPageSizeRedux)
  const ReduxEvents: any[] = useSelector((state: RootState) => state.Events.EventsRedux)
  const ReduxDates: any[] = useSelector((state: RootState) => state.Events.DatesRedux)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Events.MunicipalityRedux)


  const [events, setEvents] = useState<any[]>([]);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);

  const [showMap, setShowMap] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);


  const [dateRangeStartProp, dateRangeStart] = useState<string>('')
  const [dateRangeEndProp, dateRangeEnd] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false);




  useEffect(() => {
    setEvents(Events)
    setCheck(true)
    // fetch('http://127.0.0.1:4000/events/chania')
    // fetch("/events/chania")
    // .then(res => console.log(res.text()) )
    // .catch(error => {
    //     console.log('Error getting data: ' + error);
    // });

  }, [Events])




  useEffect(() => {
    refetch()
  }, [i18n.language])



  useEffect(() => {
    let result = utils.filterEventsArray(Events, ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp)
    setEvents(result)
   
  }, [Events, ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp])
  





  useEffect(() => {
    
    if (ReduxMunicipalities[0] == 'Δήμος Αποκορώνου') {
      // setUrl('/events/apokoronas')
      // setNameMunicipality('Εκδηλώσεις Δήμου Αποκορώνου και της περιοχής των Χανίων')
    }

  //  if(nameMunicipality == '')
    // switch (ReduxMunicipalities[0]) {
    //   case 'Δήμος Χανίων': setNameMunicipality('Εκδηλώσεις Δήμου Χανίων και της περιοχής των Χανίων'); break;
    //   case 'Δήμος Αποκορώνου': setNameMunicipality('Εκδηλώσεις Δήμου Αποκορώνου και της περιοχής των Χανίων');   break;
    // }

  }, [ReduxMunicipalities[0]])

  // console.log(nameMunicipality)
  
// https://explorechania.gr/events/apokoronas
// https://explorechania.gr/events/gavdos
// https://explorechania.gr/events/kantanouselinou
// https://explorechania.gr/events/kissamos
// https://explorechania.gr/events/platanias
// https://explorechania.gr/events/sfakia
// https://explorechania.gr/events/chania


  const showDrawer = () => { setOpen(true) }

  const onClose = () => { setOpen(false) }


  console.log('events', events)

  // const ogTitle = document.createElement('meta');
  // const ogImage = document.createElement('meta');
  // const ogDescription = document.createElement('meta');


  // ogTitle.setAttribute('property', 'og:title');
  // ogTitle.content = 'Εκδηλώσεις Δήμου Αποκορώνου και της περιοχής των Χανίων';
  // document.getElementsByTagName('head')[0].appendChild(ogTitle);



  if ( isFetching ) {
    return (<Spinner />)
  }


  return (
    <>
     
     <HelmetComponent
        title={`${t('Εκδηλώσεις Δ.')} ${t(events[0]?.municipality)} ${t('και της περιοχής των Χανίων')} | Explore Chania`}
        // descrition={event.title+', ' + ChangeMunicipality(t(event.municipality))}
        descrition={`Συγκεντρωμένες όλες οι εκδηλώσεις Δ. ${events[0]?.municipality}, εκδηλώσεις σήμερα`}
        keywords='Εκδηλώσεις σήμερα, Εκδηλώσεις στα Χανιά, Εκδηλώσεις, Χανιά, Νομός Χανίων, Κρήτη, Εκδηλώσεις στον αποκόρωνα, Εκδηλώσεις στη Γαύδο, Εκδηλώσεις στο Σέλινο, Εκδηλώσεις στην Κίσσαμο, Εκδηλώσεις στον Πλατανιά, Εκδηλώσεις στα Σφακιά , Συναυλίες, Θέατρο, Εκθέσεις, Γλέντια, Πανηγύρια'
       
      />
     
     
      <TitleAndSubtitle title={t('Βρες τις εκδηλώσεις στα Χανιά')} subTitle={t('Δείτε τις εκδηλώσεις από το Νομό')}/>

      {showMap &&
        <div className={classes.MainContainer}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className={[classes.btn, classes.btnPadding].join(" ")}>
                <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <LiefletComponent setPoisesMarkerExploration={setPoisesMarkerExploration} />
            </Col>
          </Row>
        </div>
      } 
      <div className={classes.MainContainer}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>

              <div className={classes.btnFiltersAndMap}>
                <div>
                  <Button
                    type="primary"
                    size='large'
                    className={classes.btnFilters}
                    onClick={showDrawer}> {t('Φίλτρα')}
                  </Button>
                </div>
                {
                  !showMap &&
                  <div className={classes.btn}>
                    <Button
                      type="primary"
                      icon={<MapSvg width={20} height={20} fill="#fff" />}
                      size='large' className={classes.btnMap}
                      onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')}
                    </Button>
                  </div>
                } 
              </div>


              {/* {!showMap &&
                <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;{!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
              } */}
            </Col>
          </Row>
        </div>
      </div>

      <Drawer
        bodyStyle={{ padding: '0px' }}
        autoFocus={false}
        mask={false}
        title={t('Φίλτρα')}
        placement={'left'}
        closable={false}
        onClose={onClose}
        open={open}
        // getContainer={false}
        width={350}
        extra={
          <Space>
            <Button
              icon={<CloseOutlined />}
              onClick={onClose}
              type="text"
              style={{ position: 'absolute', top: 8, right: 0 }}
            />
          </Space>
        }
      >
        <div className={classes.checkBoxesContainer}>
          <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
          <CheckBoxEventComponent checkedId={ReduxEvents} />
          <CheckBoxDatesComponent
            checkedId={ReduxDates}
            setDateRangeStart={dateRangeStart}
            setDateRangeEnd={dateRangeEnd} /> 
          <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} />
        </div>
      </Drawer>

      <div className={classes.MainContainer}>
        <Row justify="center">
          <Col xs={24} sm={24} md={5} lg={5}>
            <span className={classes.hideIsMobile}>
              <div className={classes.checkBoxesContainer}>
                <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
                <CheckBoxEventComponent checkedId={ReduxEvents} /><hr />
                <CheckBoxDatesComponent
                  checkedId={ReduxDates}
                  setDateRangeStart={dateRangeStart}
                  setDateRangeEnd={dateRangeEnd} /> <hr />
                <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} />
              </div>
            </span>
          </Col>

          <Col xs={24} sm={24} md={19} lg={19}>
            <Row justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                  {events.length !== 0
                    ?
                  <ViewEventsComponent
                      paginationNumber={ReduxPageNumberEvents}
                      paginationSize = {ReduxPageSizeEvents}
                      array={events}
                      total={events.length}
                      setEventsMarkerExploration={PoisesMarkerExploration} />
                    :
                    <>
                      <div className={classes.dontHave}>
                        { t('Δεν υπάρχουν εκδηλώσεις με τα συγκεκριμένα κριτήρια')}
                      </div>
                    </>
                  } 
              </Col>
            </Row>
          </Col>

        </Row>
        </div>
       
    </>
  )
}

export default EventsByMunicipality
