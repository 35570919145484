import React, { useState, useEffect } from "react"
import { Row, Col, Select, Tooltip } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, BellOutlined } from '@ant-design/icons';
import { useWeather, useHours } from '../../hooks/useWeather'
import { Link } from "react-router-dom"
import classes from "./Header.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo.png"
import Navbar from './Navbar'
import { Button } from 'antd';
import { useAuth } from "../../utils/auth"
import Clock from './Clock'

const Header = () => {

  const { t } = useTranslation()
  const [Top, setTop] = useState(false)
  const auth = useAuth()
  const { weatherData } = useWeather()
  // const { hours } = useHours()

  const handleChange = (value: string) => {
    i18n.changeLanguage(value)
  };


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setTop(true);
      } else {
        setTop(false);
      }
    });
  }, []);

  
  return (
    <>
      
      <Row justify="center" style={{ backgroundColor: '#f7f2ed' }}>
        <div className={classes.containerBig}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 19 }}>
            <div className={classes.containerTop}>
              <div style={{ justifySelf: 'end' }}><BellOutlined style={{ fontSize: '20px' }} /></div>
              <div className={classes.width500} style={{ justifySelf: 'center'}} >{t('Επιλέξτε τη γλώσσα προτίμησης που θέλετε να εμφανίζεται η σελίδα')}</div>
              <div style={{ justifySelf: 'end' }}>
                <span className={classes.Language}>
                  <div className={classes.container3}>
                    <Select
                      defaultValue="gr"
                      size='large'
                      style={{ width: '152px' }}
                      onSelect={handleChange}
                      options={[
                        {
                          key: 1,
                          value: 'en',
                          label: (
                            <>
                              <img width="25" height="25" src="../../img/flags/en.svg" alt="English flag" /><span className={classes.LangImg}>English</span>
                            </>
                          )
                        },
                        {
                          key: 2,
                          value: 'gr',
                          label: (
                            <>
                              <img width="25" height="25" src="../../img/flags/gr.svg" alt="Greek flag" /><span className={classes.LangImg}>Ελληνικά</span>
                            </>
                          )
                        },
                        {
                          key: 3,
                          value: 'fr',
                          label: (
                            <>
                              <img width="25" height="25" src="../../img/flags/fr.svg" alt="France flag" /><span className={classes.LangImg}>Français</span>
                            </>
                          )
                        },
                        {
                          key: 4,
                          value: 'de',
                          label: (
                            <>
                              <img width="27" height="26" src="../../img/flags/de.svg" alt="Duitsland flag" /><span className={classes.LangImg}>Deutsch</span>
                            </>
                          )
                        },
                        {
                          key: 5,
                          value: 'ru',
                          label: (
                            <>
                              <img width="27" height="26" src="../../img/flags/ru.svg" alt="Russian flag" /><span className={classes.LangImg}>Русский</span>
                            </>
                          )
                        }
                      ]}
                    />
                  </div>
                </span>
              </div>
            </div>
          </Col>
        </div>
      </Row>
   

      {/* For Commite */}
      <div className={classes.containerBig}>
        
        <Row justify="center" >

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <div className={classes.div}>
              <span className={classes.Language}>
                
                <div className={classes.container}>
                  
                  <div className={classes.clockDiv} >
                    <Tooltip placement="top" color='#63584F' title={t('Τοπική ώρα στην Κρήτη')} >
                      <ClockCircleOutlined /> <Clock />
                    </Tooltip>
                  </div>
                  <div className={classes.weatherDiv}>
                    &#9728;
                    <Tooltip placement="top" color='#63584F' title={t('Χαμηλότερη Θερμοκρασία')} > L {weatherData[0] && weatherData[0].degree_min} &#8451;&nbsp;  &#10072; &nbsp;</Tooltip>
                    <Tooltip placement="top" color='#63584F' title={t('Υψηλότερη Θερμοκρασία')} > H {weatherData[0] && weatherData[0].degree_max} &#8451;</Tooltip>
                  </div>

                  <div style={{ justifySelf: 'start' }}><EnvironmentOutlined />&nbsp; {t('Χανιά')}</div>
                </div>

              </span>
            </div>
          </Col>

        </Row>
      </div> 

      <hr style={{ borderTop: '1px solid rgb(137, 117, 99)' }}/>
      









      {/* <div className={classes.containerBig}>  */}

      <div className={ !Top ? [classes.header, classes.flexContainer].join(" ") : [classes.header, classes.flexContainer, classes.BorderBottom].join(" ")  }>
        
        <div className={classes.containerBotton}>

          <div className={classes.app_logo}>
            <Link to="/">
              <img
                src={logo}
                alt="Logo Chania"
              />
            </Link>
          </div>
          
          <Navbar />

          <div>
            <span className={classes.searchButton} ><Link to="/search"><Button size="large" style={{ fontSize: '19px', height: '44px', width: '160px', color:'#63584F', border: "1px solid #63584F" }} icon={<SearchOutlined style={{color: '#63584F'}}/>} >{ t('Αναζήτηση')}</Button></Link></span>
          </div>

        </div>
        </div>
        {/* </div> */}
    </>
  );
};

export default Header