import React, { useState, useEffect } from "react"
import { useQuery } from '@tanstack/react-query'
import { axiosExplorations } from "../services/exploration"
import { PoisCategoriesProps, subCategoriesProps } from "./props/exploration"
import { PoisProps, PoisProps2 } from "./props/pois"
import { GeneralProps } from "./props/general"
import axios from 'axios'
import ky from 'ky'
import { API, Header } from '../services/configuration'




export const useExploration = () => {
  
  const [poisCategories, setPoisCategories] = useState<PoisCategoriesProps[]>([]);
  const [arrayExplorationSearchData, setArrayExplorationSearchData] = useState<PoisCategoriesProps[]>([]);

  let arrayPoisCategories: PoisCategoriesProps[] = []
  let arraySubCategories: subCategoriesProps[]=[]
  let arrayExplorationSearch:any = []
  
  const { isLoading, error, refetch } = useQuery(['categories'], () => axiosExplorations.getCategories(), {
   
    onSuccess: (data) => {

      data.map((category: any, index:number) => {

        for (let i = 0; i < data[index].children.length; i++){

          arraySubCategories.push({
            id: data[index].children[i].id,
            name: data[index].children[i].name,
            pois_count: data[index].children[i].pois_count,
          })
        } 

        arrayPoisCategories.push({
          id: category.id,
          name: category.name,
          subCategoriesCount: category.pois_count,
          subCategories: arraySubCategories,
        })

        arraySubCategories = []
      })
      

      arrayPoisCategories.map((item: PoisCategoriesProps) => {

        item.subCategories.map((it: subCategoriesProps) => {
          arrayExplorationSearch.push({
            id: it.id,
            item: it.name,
            value: it.name,
            subCategoriesCount: it.pois_count,
          })
        })
      })
      
      
      setArrayExplorationSearchData(arrayExplorationSearch)
      setPoisCategories(arrayPoisCategories)
      
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, error, poisCategories, arrayExplorationSearchData, refetch }
}


export const usePois = (id: string) => {

  let poisArray: PoisProps2;
  let response: any
  const [PoisData, setPoi] = useState<PoisProps2>(); 
  const [arrayPois, setArrayPois] = useState<PoisProps2>();

  const { isLoading, isFetching, refetch: refe } = useQuery(['pois', id], () => axiosExplorations.getPois(id), {
   
    onSuccess: (data) => {


      let categories: any = data.category_path.length !== 1 ? [...data.category_path[0], ...data.category_path[1]] : data.category_path[0]

        poisArray = {
          
          id: data.id,
          title: data.name,
          description: data.portal_main_info,
          lat: data.coordinates[0].latitude,
          lng: data.coordinates[0].longitude,
          municipality: data.extras._municipalityName.value,
          tags: data.tags,
          image: data.main_image,
          images: data.files[1],
          images3d: data.files[2] ? data.files[2] : [],
          video: data.files[5] ? data.files[5] : '',
          mp3: data.files[4],
          category:  Array.from(new Set(categories)),
          rating: data.rating !== null ? data.rating.length !== 0 ? data.rating : 0 : 0,
          google_rating: data.google_place,
          typesOfRoute: data.extras._point_codes_per_route["value"],
        }

      // setPoi(poisArray)
      
      const datas = GetServerSideProps(poisArray)
      Promise.all([datas]).then(results => { setPoi(results[0])  })

    },
    
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, PoisData, refe }
}

const GetServerSideProps = async(poi: PoisProps2) => {

  let poiFinal: PoisProps2
  let google_vote: any

  if (poi.rating.length !== 0) {
      
   await fetch(`/details/json?place_id=${poi.google_rating}&fields=name%2Crating&key=AIzaSyDB3Wu5LUt5Hcah934FZHr-JM9M5T48hXE`)
    .then(async response => {
      let { result } = await response.json();
      // console.log(result.rating);
      poiFinal = { ...poi, google_rating: '0' }
      
    })
    .catch(error => {
        console.log('Error getting data: ' + error);
    });

    poiFinal = { ...poi }
  }

    else 
      poiFinal={ ...poi, google_rating: 0}
    
  
    google_vote = (poiFinal.google_rating.status === 'OK' && poiFinal.google_rating.result.rating !== undefined) ? poiFinal.google_rating.result.rating : 0

    poiFinal={ ...poiFinal, rating: poi.rating }

  
    // console.log('poiFinal', poiFinal)
  
  return poiFinal
}

const GetServerSideProps2 = (poi: PoisProps2) => {

  let poiFinal: PoisProps2 = {...poi}
  // let google_vote: any
  // const [ser, setSer] = useState('2')


  // const data =  fetch(`/details/json?place_id=${poi.google_rating}&fields=name%2Crating&key=AIzaSyDB3Wu5LUt5Hcah934FZHr-JM9M5T48hXE`)
  //         .then(async response => {
  //           let { result } = await response.json();
  //           console.log(result.rating);
  //           poiFinal = { ...poi, google_rating: result.rating }
  //         })
 
  
  const fetchPromise = fetch(`/details/json?place_id=${poi.google_rating}&fields=name%2Crating&key=AIzaSyDB3Wu5LUt5Hcah934FZHr-JM9M5T48hXE`);
  fetchPromise.then(response => {
    return response.json();
  }).then(({ result }) => {
    console.log(result.rating);
    poiFinal = { ...poi, google_rating: result.rating }
  });
  
  
  
  // console.log('Data', data)

   return poiFinal

}







export const usePoises = () => {

  let poisesArray: GeneralProps[] = []

  let poisesArray2: any = []

  const [Poises, setPoises] = useState<GeneralProps[]>([]); 
  
  const { isLoading, isFetching, error } = useQuery(['poises'], () => axiosExplorations.getPoises(), {
   
    onSuccess: (data) => {
      
      data.map((array: PoisProps) => {

        array.featured == 0
          ?
          poisesArray.push({
            id: array.id,
            image: array.main_image,
            municipality: array.extras._municipalityName.value,
            title: array.name,
            lat: array.coordinates[0].latitude,
            lng: array.coordinates[0].longitude,
            rate: array.rating !== null ? array.rating : 0,
            vote: array.votesNum !== null ? array.votesNum : 0,
            category_id: array.category_path_ids[0][1],
            category: array.category_path[0][1],
            text: array.portal_main_info,
            featured: array.featured
          })
          :
          poisesArray.unshift({
            id: array.id,
            image: array.main_image,
            municipality: array.extras._municipalityName.value,
            title: array.name,
            lat: array.coordinates[0].latitude,
            lng: array.coordinates[0].longitude,
            rate: array.rating !== null ? array.rating : 0,
            vote: array.votesNum !== null ? array.votesNum : 0,
            category_id: array.category_path_ids[0][1],
            category: array.category_path[0][1],
            text: array.portal_main_info,
            featured: array.featured
          })
        
      })


      data.map((array: PoisProps) => {
        poisesArray2.push(array.name)
      })
      const filteredArr = [...new Set(poisesArray2)];
      var newArray: any = [];
      var newArray = poisesArray2.filter(function(elem: any, pos: any) {
        return poisesArray2.indexOf(elem) == pos;
      });

      // console.log('filteredArr', filteredArr)
      
      setPoises(poisesArray)

    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { Poises }
}



export const usePoisOmeka = (id: string) => {

  let poisArray: PoisProps2;

  const [PoisData, setPoise] = useState<PoisProps2>(); 
  
  const { isLoading, isFetching, error } = useQuery(['pois', id], () => axiosExplorations.getPoisOmeka(id), {
   
    onSuccess: (data) => {

        poisArray = {
          
          id: data.id,
          title: data.name,
          description: data.portal_main_info,
          lat: data.coordinates[0].latitude,
          lng: data.coordinates[0].longitude,
          municipality: data.extras._municipalityName.value,
          tags: data.tags,
          image: data.main_image,
          images: data.files[1],
          images3d: data.files[2] ? data.files[2] : [],
          video: data.files[5] ? data.files[5] : '',
          mp3: data.files[4],
          category: data.category_path[0],
          rating: data.rating !== null ? data.rating.length !== 0 ? data.rating : 0 : 0,
          google_rating: data.google_place,
          typesOfRoute: data.extras._point_codes_per_route["value"],

        }

      setPoise(poisArray)
    },
    
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, PoisData }
}
