import React, {useState, useEffect } from "react"
import { Row, Col, Button, Drawer, Space } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useAreas } from '../../hooks/useAreas'
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import { CloseOutlined } from '@ant-design/icons'
import CheckBoxEventComponent from '../../components/CheckBoxGroup/Event'
import CheckBoxDatesComponent from '../../components/CheckBoxGroup/Date'
import CheckBoxMunicipalityComponent from '../../components/CheckBoxGroup/MuniciplaityByMunicipalityRoutes'
import utils from '../../utils'
import i18n from '../../translation'
import ViewEventsComponent from './viewEvents'
import LiefletComponent from './Lieflet'

import MapSvg from '../../img/svg/map'
import type { RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { useEventsMap } from '../../hooks/useEvent'
import { ExplorationPageNumberRedux } from '../../redux/mapPage/category'
import { useEventsMapWithSeoTags } from '../../hooks/useEvent'

const Events: React.FC = () => {

  const { t } = useTranslation()

  // const { isLoading, isFetching, Events, refetch } = useEventsMap()
  const { isLoading, isFetching, Events, refetch } = useEventsMapWithSeoTags('chania', 180)
  const { areas } = useAreas()
  const dispatch = useDispatch()


  
  const ReduxPageNumberEvents: number = useSelector((state: RootState) => state.Events.EventsPageNumberRedux)
  const ReduxPageSizeEvents: number = useSelector((state: RootState) => state.Events.EventsPageSizeRedux)
  const ReduxEvents: any[] = useSelector((state: RootState) => state.Events.EventsRedux)
  const ReduxDates: any[] = useSelector((state: RootState) => state.Events.DatesRedux)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Events.MunicipalityRedux)


  const [events, setEvents] = useState<any[]>([]);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);

  const [showMap, setShowMap] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);


  const [dateRangeStartProp, dateRangeStart] = useState<string>('')
  const [dateRangeEndProp, dateRangeEnd] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setEvents(Events)
    setCheck(true)
  }, [Events])


  useEffect(() => {
    refetch()
    dispatch(ExplorationPageNumberRedux(1))
  }, [i18n.language])



  useEffect(() => {         
    let result = utils.filterEventsArray(Events, ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp)
    setEvents(result)
  }, [Events, ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp])
  


  const showDrawer = () => { setOpen(true) }

  const onClose = () => { setOpen(false) }


  if (isLoading) {
    return (<Spinner />)
  }

  // if ( isFetching ) {
  //   return (<Spinner />)
  // }
  // console.log(events)

  return (
    <>
      <HelmetComponent
        title={t('events_title') as string}
        descrition={t('events_description') as string}
        keywords={t('events_keywords') as string}
      />
     
      <TitleAndSubtitle title={t('Βρες τις εκδηλώσεις στα Χανιά')} subTitle={t('Δείτε τις εκδηλώσεις από το Νομό')}/>

      {showMap &&
        <div className={classes.MainContainer}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className={[classes.btn, classes.btnPadding].join(" ")}>
                <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <LiefletComponent setPoisesMarkerExploration={events} />
            </Col>
          </Row>
        </div>
      } 
      <div className={classes.MainContainer}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>

              <div className={classes.btnFiltersAndMap}>
                <div>
                  <Button
                    type="primary"
                    size='large'
                    className={classes.btnFilters}
                    onClick={showDrawer}> {t('Φίλτρα')}
                  </Button>
                </div>
                {
                  !showMap &&
                  <div className={classes.btn}>
                    <Button
                      type="primary"
                      icon={<MapSvg width={20} height={20} fill="#fff" />}
                      size='large' className={classes.btnMap}
                      onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')}
                    </Button>
                  </div>
                } 
              </div>


              {/* {!showMap &&
                <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;{!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
              } */}
            </Col>
          </Row>
        </div>
      </div>

      <Drawer
        bodyStyle={{ padding: '0px' }}
        autoFocus={false}
        mask={false}
        title={t('Φίλτρα')}
        placement={'left'}
        closable={false}
        onClose={onClose}
        open={open}
        // getContainer={false}
        width={350}
        extra={
          <Space>
            <Button
              icon={<CloseOutlined />}
              onClick={onClose}
              type="text"
              style={{ position: 'absolute', top: 8, right: 0 }}
            />
          </Space>
        }
      >
        <div className={classes.checkBoxesContainer}>
          <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
          <CheckBoxEventComponent checkedId={ReduxEvents} />
          <CheckBoxDatesComponent
            checkedId={ReduxDates}
            setDateRangeStart={dateRangeStart}
            setDateRangeEnd={dateRangeEnd} /> 
          <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} />
        </div>
      </Drawer>

      <div className={classes.MainContainer}>
        <Row justify="center">
          <Col xs={24} sm={24} md={5} lg={5}>
            <span className={classes.hideIsMobile}>
              <div className={classes.checkBoxesContainer}>
                <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
                <CheckBoxEventComponent checkedId={ReduxEvents} /><hr />
                <CheckBoxDatesComponent
                  checkedId={ReduxDates}
                  setDateRangeStart={dateRangeStart}
                  setDateRangeEnd={dateRangeEnd} /> <hr />
                <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} />
              </div>
            </span>
          </Col>

          <Col xs={24} sm={24} md={19} lg={19}>
            <Row justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                  {events.length !== 0
                    ?
                    <ViewEventsComponent
                      paginationNumber={ReduxPageNumberEvents}
                      paginationSize = {ReduxPageSizeEvents}
                      array={events}
                      total={events.length}
                      setEventsMarkerExploration={PoisesMarkerExploration} />
                    :
                    <>
                      <div className={classes.dontHave}>
                        { t('Δεν υπάρχουν εκδηλώσεις με τα συγκεκριμένα κριτήρια')}
                      </div>
                    </>
                  } 
              </Col>
            </Row>
          </Col>

        </Row>
      </div>
    </>
  )
}

export default Events
