import ky from 'ky'
import { Cors} from './configuration'
import { APIRoutes, HeaderRoutes } from './configuration'
import i18n from '../../src/translation'

export const axiosSearch = {

  async getSearch(searchString: string, lang: string) {

    // console.log(lang)

    const pois : any = await ky.get(`https://chaniaroutes.mitos.cityguideplatform.com/api/pois?fetch_files=1&search_keyword=${searchString.replace(/\s*\([^)]*\)/g, "")}&lang=${lang === 'gr' ? 'el' : lang }`,
      {
        headers: { 
          'X-API-KEY': 'jVxD4ZhUUclEgIa36UwI'
        },
        timeout: 20000, // 20 секунд
        retry: 3 // Повторить запрос 3 раза в случае ошибки
      }
    ).json()


    const routes : any = await ky.get(`https://chaniaroutes.mitos.cityguideplatform.com/api/routes?lang=${lang === 'gr' ? 'el' : lang }&search_keyword=${searchString.replace(/\s*\([^)]*\)/g, "")}`, {
      headers: { 
        'X-API-KEY': 'jVxD4ZhUUclEgIa36UwI'
      },
      timeout: 20000, // 20 секунд
      retry: 3 // Повторить запрос 3 раза в случае ошибки
    }).json()


    const { events } : any = await ky.get(`https://events.explorechania.gr/api/${lang === 'gr' ? 'el' : lang }/events/search?keywords=${searchString.replace(/\s*\([^)]*\)/g, "")}`, {
  
      headers: Cors,
      timeout: 30000, // 20 секунд
      retry: 3 // Повторить запрос 3 раза в случае ошибки

    }).json()


    console.log('routes', routes)

    return  [pois.success, routes.success, events] 
 
  },

  async getSearchForAutocomplite(lang: string) {

    let ArrayRoutes: any = ['3_2', '3_3', '3_4', '3_6', '3_1'];
    let routeData: any = [];
    let autoComplitePoises: any = [];

    for (let i = 0; i < ArrayRoutes.length; i++) {

      routeData = await ky.get(`${APIRoutes}/route?id=${ArrayRoutes[i]}&fetch_files=1&lang=${lang }`, { headers: HeaderRoutes }).json();

      autoComplitePoises.push(routeData)
  
    }

    return [...new Set(autoComplitePoises)] 
  },

}




// async getSearch(searchString: string) {
   
//   const data = fetch('https://chania.mitos.cityguideplatform.com/api/pois?fetch_files=1&search_keyword=φυσιολατρικό&lang=el', {
//     headers: { 
//     'X-Authorization': 'hfG7u8C4UNMw17E6Jyzq'
//   }
//   });

//   return (await data).json();