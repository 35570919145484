import ky from 'ky'
import {APIEvents} from './configuration'
import i18n from '../../src/translation'


export const axiosEvents = {

  async getEvent(id: string) {
    // const data: any = await ky.get(`${APIEvents}/${i18n.language == 'gr' ? 'el' : i18n.language}/events/${id}`).json()
    const data: any = await ky.get(`${APIEvents}/${i18n.language == 'gr' ? 'el' : i18n.language}/events/${id}`, { timeout: 20000 }).json()
    return data[0]
  },

  async getEvents(limit:number) {
    const data: any = await ky.get(`${APIEvents}/${i18n.language == 'gr' ? 'el' : i18n.language}/events/?limit=${limit}`, { timeout: 20000 }).json()
    return data.events
  }, 




  async getEventsForSeo(url: string) {
   const dataForSeo = await fetch(`http://localhost:3000/events/${url}`)
  //  console.log(dataForSeo)
   return dataForSeo
  }, 



  async getEventsCurrent() {
    const data:any = await ky.get(`${APIEvents}/${i18n.language == 'gr' ? 'el' : i18n.language }/events/current`).json()
    return data.events
  },

  async getEventsUpcoming() {
    const data:any = await ky.get(`${APIEvents}/${i18n.language == 'gr' ? 'el' : i18n.language }/events/upcoming`).json()
    return data.events
  }

  
  
  // async getEvent(id: string) {
  //   const data: any = await ky.get(`${APIEvents}/el/events/${id}`).json()
  //   return data[0]
  // },

  // async getEvents() {
  //   const data: any = await ky.get(`${APIEvents}/el/events/?limit=1000`).json()
  //   return data.events
  // }, 

  // async getEventsCurrent() {
  //   const data:any = await ky.get(`${APIEvents}/el/events/current`).json()
  //   return data.events
  // },

  // async getEventsUpcoming() {
  //   const data:any = await ky.get(`${APIEvents}/el/events/upcoming`).json()
  //   return data.events
  // }

  
  
}